import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Card } from '../components/Card';
import IdentoIcon from '../components/icons/IdentoIcon';
import TruzztboxIcon2 from '../components/icons/TruzztboxIcon2';
import NextcloudLogo from '../components/icons/NextcloudLogo';
import AddIcon from '../images/AddIcon';
import UserService from '../services/UserService';
import Breadcrumb from '../components/Breadcrumb';

const API_URL = process.env.REACT_APP_API_URL;
const NEXTCLOUD_URL = process.env.REACT_APP_NEXTCLOUD_URL

const Truzztbox = () => {
    const { t } = useTranslation();
    const [profile, setProfile] = useState("");
    const [box, setBox] = useState(null);
    const [screenPage, setScreenPage ] = useState('');
    async function fetchBox() {
        const result = await axios.get(`${API_URL}/truzztbox`, {
            headers: {
                'Authorization': "Bearer " + UserService.getToken()
            }
        });
        setBox(result.data);
    }

    useEffect(() => {
        fetchBox();
    }, []);

    const fetchProfile = async () => {
    const result = await axios.get(`${API_URL}/verify/basicdata`, {
            headers: {
                'Authorization': "Bearer " + UserService.getToken()
            }
        });
        setProfile(result.data);
    }

    useEffect(() => {
        fetchProfile();
    }, []);

    if(!box) return <h1>keine truzzt box vorhanden</h1>

    const RenderIdentoApp = () => {
        return (
            <div id="basic-data">
                <section className="card-wrap small-cards">
                    <Card className="idento-gradient">
                        <IdentoIcon/>
                        <span><strong>idento.one</strong></span>
                    </Card>
                    <Card onClick={()=> window.location.href="https://mds-test.truzztbox.org/dashboard"}>
                        <TruzztboxIcon2/>
                        <span><strong>Connector</strong></span>
                    </Card>
                    <Card onClick={()=> window.location.replace(NEXTCLOUD_URL)}>
                        <NextcloudLogo/>
                        <span><strong>Nextcloud</strong></span>
                    </Card>
                    <Card>
                        <AddIcon/>
                        <span>{t('truzztbox.addapp1')}<br />{t('truzztbox.addapp2')}</span>
                    </Card>
                </section>
                <section className="card-wrap big-cards">
                    <Card onClick={() => setScreenPage('Basic')}>
                        <h2 style={{marginTop:"8px"}}>{t('truzztbox.basicdata')}</h2>
                        <span className="color-key"><strong>{t('truzztbox.verified')}</strong></span>
                            <p style={{color: "#606060"}}>
                                {t('truzztbox.accessbasicdata')}
                            </p>
                        <span className="color-orange last-update-span">
                            {t('dashboard.lastUpdate')}<strong>22.04.2022</strong>
                        </span>
                    </Card>
                    <Card to="/takeout">
                    <h2 style={{marginTop:"8px"}}>Data Takeout</h2>
                        <span className="color-key"><strong>{t('truzztbox.youraccountdata')}</strong></span>
                            <p style={{color: "#606060"}}>
                                {t('truzztbox.exportdata')}
                            </p>
                    </Card>
                </section>
            </div>
        )
    }

    const RenderBasicData = () => {
        return (
            <div id="basic-data">
                <section className="card-wrap small-cards">
                    <Card style={{cursor:"pointer"}} onClick={() => setScreenPage('')}>
                        <span><strong>{t('truzztbox.backtoapp')}</strong></span>
                    </Card>
                </section>
                <section className="card-wrap big-cards">
                    <Card>
                        <p>{t('truzztbox.firstname')} {profile.result.data.Document_Information.firstName}</p>
                        <p>{t('truzztbox.lastname')} {profile.result.data.Document_Information.lastName}</p>
                        <p>{t('truzztbox.birthdate')} {profile.result.data.Document_Information.dob}</p>
                        <p>{t('truzztbox.age')} {profile.result.data.Document_Information.underage}</p>
                        <p>{t('truzztbox.Nationality')} {profile.result.data.Document_Information.documentCountry}</p>
                        <p>{t('truzztbox.Document')}: {profile.result.data.Document_Information.documentId}</p>
                    </Card>
                    <Card>
                        <p>{t('truzztbox.Continent')} {profile.result.data.IP_Information.continent}</p>
                        <p>{t('truzztbox.Country')} {profile.result.data.IP_Information.country}</p>
                        <p>{t('truzztbox.state')} {profile.result.data.IP_Information.county}</p>
                        <p>{t('truzztbox.city')} {profile.result.data.IP_Information.city}</p>
                        <p>{t('truzztbox.zipcode')} {profile.result.data.IP_Information.country_code}</p>
                    </Card>
                </section>
            </div>
        )
    }

    function renderBreadcrumb() {
        return(            
            <section id="head-row">
                <div id="breadcrumb">
                    <p>{t('dashboard.youarehere')} &gt; <strong>truzzt box</strong></p>
                    <br/>
                    <h1>truzzt box</h1>
                </div>
            </section>
        )
    }

    const renderMain = () => {
        switch (screenPage) {
            case 'Basic':
                return (
                    <>
                        {renderBreadcrumb()}
                        {RenderBasicData()}
                    </>
                )
            default:
                return (
                    <>
                        {renderBreadcrumb()}
                        {RenderIdentoApp()}
                    </>
                )
        }
    }

    return (
        <main>
            <>
                {renderMain()}
            </>
        </main>
    )
}

export default Truzztbox;
