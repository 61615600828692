import {Link} from 'react-router-dom';
import YellowIcon from "./icons/YellowIcon";
import RedIcon from "./icons/RedIcon";
import React from 'react';

export const Card = (props) => {
    if (props.to) {
        return (
            <Link {...props} className={`idento-card ${props.className !== undefined ? props.className : ""}`}>
                {props.children}
            </Link>
        )
    } else if (props.to && props.connection === true ) {
        return (
            <Link {...props} className={`idento-card ${props.className !== undefined ? props.className : ""}`}>
                <YellowIcon/>
                {props.children}
            </Link>
            )
    } else if (props.to && props.connection === false ) {
        return (
            <Link {...props} className={`idento-card ${props.className !== undefined ? props.className : ""}`}>
                <RedIcon/>
                {props.children}
            </Link>
            )
    } else {
        return (<div {...props} className={`idento-card ${props.className !== undefined ? props.className : ""}`}>
            {props.children}
        </div>)
    }
}
