import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SliderComponent from "./ConnectorSlider";
import axios from "axios";
import { Link } from "react-router-dom";
import gif from '../images/icons/w8screentruzztbox.gif';
import UserService from '../services/UserService';

const API_URL = process.env.REACT_APP_API_URL;

const ConfigContent = ( ) => {
    const { t } = useTranslation();
    const [screenPage, setScreenPage ] = useState('info');
    const [w8Screen, setW8Screen] = useState(false);
    let [sliderValues, setSliderValue] = useState({
        cpu: 1,
        ram: 2,
        storageSize: 16,
        storageType: "HDD",
        autoScale: false,
        count: 1,
        cpuFamily: 'Intel'
    });

    const doOrder = async () => {
        try {
            const payload = {
                node_count: sliderValues.count,
                core_count: sliderValues.cpu,
                ram_size: sliderValues.ram,
                storage_type: sliderValues.storageType,
                storage_size: sliderValues.storageSize
            }
            await axios.post(`${API_URL}/truzztbox/create`, payload, { headers: { 'Authorization': "Bearer " + UserService.getToken() } });
            setW8Screen(!w8Screen);
        } catch (error) {
            console.log(error.response.data);
        }
    }


    const handleSliderChange = (type, value) => {
        if (sessionStorage.getItem('savedPack')) {
            sessionStorage.removeItem('savedPack')
            sessionStorage.removeItem('savedSliders')
        }
        setSliderValue({ ...sliderValues, [type]: value });
    }


    const renderPackageInfo = () => {
        return (
            <div>
                <div>
                    <div id="select-container">
                        <h1 style={{color: '#F3AE0E'}}>truzzt box mini</h1>
                        <ul className='truzztbox-functions'>
                            <li><span class="custom-bullet"></span> {t('customisationColumn.yourpersonaldashboard')}</li>
                            <li><span class="custom-bullet"></span> {t('customisationColumn.s3storage')}</li>
                            <li><span class="custom-bullet"></span> {t('customisationColumn.virtualcomputing')}</li>
                            <li><span class="custom-bullet"></span> {t('customisationColumn.privatecomputingcloud')}</li>
                            <li><span class="custom-bullet"></span> {t('customisationColumn.updownloadfiles')}</li>
                            <li><span class="custom-bullet"></span> {t('customisationColumn.policymanagment')}</li>
                            <li><span class="custom-bullet"></span> {t('customisationColumn.officetools')}</li>
                            <li><span class="custom-bullet"></span> {t('customisationColumn.calendar')}</li>
                        </ul>
                        <button style={{color: 'black', marginTop: '123px'}} onClick={e => { e.preventDefault(); doOrder(); }}>
                            {t('customisationColumn.getyour')} truzzt box mini {t('customisationColumn.getyour2')}                     
                        </button>
                        <p style={{color: '#F3AE0E', marginTop: '20px' }}>{t('customisationColumn.moreoptions')}</p>
                    </div>
                </div>
                <div>
                    <div id="select-container" style={{marginLeft: '620px',marginTop: '-865px'}}>
                        <h1 style={{color: '#F3AE0E'}}>truzzt box pro</h1>
                        <ul className='truzztbox-functions2'>
                            <li><span class="custom-bullet"></span> {t('customisationColumn.allfunctions')}</li>
                            <li><span class="custom-bullet"></span> Virtual Computing (dedicated resources)</li>
                            <li><span class="custom-bullet"></span> {t('customisationColumn.truzztboxconfigurator')}</li>
                            <li><span class="custom-bullet"></span> EDC connector as a service</li>
                            <li><span class="custom-bullet"></span> {t('customisationColumn.connectordashboard')}</li>
                            <li><span class="custom-bullet"></span> {t('customisationColumn.otherconnectors')}</li>
                            <li><span class="custom-bullet"></span> {t('customisationColumn.ssimanagment')}</li>
                            <li><span class="custom-bullet"></span> {t('customisationColumn.policyhandling')}</li>
                            <li><span class="custom-bullet"></span> {t('customisationColumn.walletfunction')}</li>
                        </ul>
                        <button style={{color: 'black'}} onClick={() => setScreenPage('customization')}>
                            {t('customisationColumn.getyour')} truzzt box pro {t('customisationColumn.getyour3')}                 
                        </button>
                        <p style={{color: '#F3AE0E', marginTop: '20px'}}>{t('customisationColumn.moreoptions')}</p>
                    </div>
                </div>
            </div>
        )
    }

    const infoColumn = () => {
        if (!w8Screen) {
            return renderPackageInfo()
        }

        return (
            <div className='placeholder-column'>
                <h1 style={{marginTop: "10%"}} >{t('customisationColumn.yourTruzzzboxes1')}<br/>{t('customisationColumn.yourTruzzzboxes2')}</h1>
                <h1>{t('customisationColumn.configuringnow')}</h1>
                <h3 style={{ marginBottom: "30px" }}>{t('customisationColumn.Thiscantakeupto15minutes')}</h3>
                <img style={{width: "50%"}} src={gif} alt="loading..."/>
                <Link to="/dashboard">
                    <h3 style={{ color: "#6D7278", marginTop: "40px" }}>{t('customisationColumn.backtodashboard')}</h3>
                </Link>
            </div>
        )
    }

    const customisationColumn = () => {
        return (
            <div>
                <p style={{color: '#F3AE0E', marginTop: '20px', marginBottom: '20px', cursor: 'pointer'}} onClick={() => setScreenPage('info')}>{t('customisationColumn.backtodashboard')}</p>
                <div id="select-container" style={{height: '700px', widht: '570px'}}>
                    <form id="customisationForm" action="#" method="get">
                        <h1 style={{color: '#F3AE0E', marginBottom: '63px'}}>truzzt box pro</h1>
                        <h2 style={{marginBottom: '63px'}}>{t('customisationColumn.selectionoverview')}</h2>
                        <h3 style={{marginBottom: '13px'}}>{t('customisationColumn.CPU-selection')}</h3>
                        <div style={{marginBottom: '41px'}} className="slider-wrap">
                            <SliderComponent
                                sliderType="cpu"
                                stepSize={sliderValues.cpuFamily === "Amd" ? 1 : 1}
                                minValue={0}
                                maxValue={8}
                                value={sliderValues.cpuFamily === "Intel" ? sliderValues.cpu % 1 === 0 ? sliderValues.cpu : sliderValues.cpu-- : sliderValues.cpu}
                                handleChange={handleSliderChange}
                            />
                        </div>
                        <h3 style={{marginBottom: '13px'}}>{t('customisationColumn.RAM-selection')}</h3>
                        <div style={{marginBottom: '41px'}} className="slider-wrap">
                            <SliderComponent
                                sliderType="ram"
                                stepSize={1}
                                minValue={0}
                                maxValue={20}
                                value={sliderValues.ram}
                                handleChange={handleSliderChange}
                            />
                        </div>
                        <h3 style={{marginBottom: '13px'}}>{t('customisationColumn.memoryselection')}</h3>
                        <div style={{marginBottom: '69px'}} className="slider-wrap">
                            <SliderComponent
                                sliderType="storageSize"
                                stepSize={1}
                                minValue={0}
                                maxValue={600}
                                value={sliderValues.storageSize}
                                handleChange={handleSliderChange}
                            />
                        </div>
                        <button style={{ marginBottom: "20px" }} onClick={e => { e.preventDefault(); doOrder(); setScreenPage('info');}}>
                            {w8Screen ? t('customisationColumn.confirm') : t('customisationColumn.confirm')}
                        </button>
                        <p style={{color: '#F3AE0E', marginTop: '20px'}}>{t('customisationColumn.moreoptions')}</p>
                    </form>
                </div>
            </div>
        )
    }

    const renderMain = () => {
        switch (screenPage) {
            case 'info':
                return (
                    <>
                        {infoColumn()}
                    </>
                )
            case 'customization':
                return (
                    <>
                        {customisationColumn()}
                    </>
                )
            default:
                return (
                    <>
                        {infoColumn()}
                    </>
                )
        }
    }

    return (
        <main>
            <>
                {renderMain()}
            </>
        </main>
    )
}

export default ConfigContent;
