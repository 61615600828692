import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import UserService from "../services/UserService";

const NavColumn = () => {
    const {t} = useTranslation()

    return (
        <nav>
            <ul>
                <li><Link to="/dashboard">{t('nav.dashboard')}</Link></li>
                <li><Link to="/settings">{t('nav.settings')}</Link></li>
                <hr/>
                <li><a onClick={() => UserService.doLogout()}>{t('nav.logout')}</a></li>
            </ul>
        </nav>
    )
}

export default NavColumn;
