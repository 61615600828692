import React from 'react';
import Icon from './Icon';

export default () => {
    return (
        <div className='cls-200'>
            <a target="_blank"><Icon iconName="Truzztbox2"/></a>
        </div>
    )
}
