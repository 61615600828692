import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import HttpService from "./services/HttpService";
import UserService from "./services/UserService";
import RenderOnAuthenticated from "./components/RenderOnAuthenticated";
import RenderOnAnonymous from "./components/RenderOnAnonymus";
import Login from "./pages/Login";

const initOptions = {
  checkLoginIframe: false
}

const renderApp = () => ReactDOM.render(
  <React.StrictMode>
    <RenderOnAnonymous>
      <Login/>
    </RenderOnAnonymous>
    <RenderOnAuthenticated>
      <App/>
    </RenderOnAuthenticated>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
UserService.initKeycloak(renderApp);
HttpService.configure();
