import React from 'react';

const AddIcon = props => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120" {...props}>
        <path className="cls-1"
              d="M60,0a60,60,0,1,0,60,60A60,60,0,0,0,60,0Zm0,118a58,58,0,1,1,58-58A58.07,58.07,0,0,1,60,118Z"/>
        <polygon className="cls-1"
                 points="61 48.38 59 48.38 59 59 48.38 59 48.38 61 59 61 59 71.62 61 71.62 61 61 71.62 61 71.62 59 61 59 61 48.38"/>
    </svg>
)

export default AddIcon