import React from 'react';
import Icon from './Icon';

export default () => {
    return (
        <div className="cls-11">
            <a target="_blank"><Icon className="cls-14" width="18px" height="18px" iconName="MobilityIcon"/></a>
        </div>
    )
}
