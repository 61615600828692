import logoWithClaim from '../images/icons/truzzt.png';
import {Helmet} from "react-helmet";
import { Redirect } from 'react-router-dom';
import UserService from "../services/UserService";

const Login = () => {

    if(UserService.isLoggedIn()) {
        return <Redirect to={"/dashboard"}/>
    }

    return (
        <div id="login-card">
            <Helmet>
                <title>idento.one | Login</title>
            </Helmet>
            <img src={logoWithClaim} alt="idento.one-Logo"/>
            <button className="btn btn-lg btn-warning" onClick={() => UserService.doLogin()}>Login</button>
        </div>
    )
}

export default Login;
