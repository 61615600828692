import React from 'react';
import Icon from './Icon';

export default () => {
    return (
        <div className="media-icons">
            <a target="_blank"><Icon width="18px" height="18px" iconName="OvalIcon"/></a>
        </div>
    )
}
