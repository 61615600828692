import { Card } from '../components/Card';
import { useTranslation } from 'react-i18next';
import IdProofIcon from '../components/icons/IdProofIcon';
import CloudIcon2 from '../components/icons/CloudIcon2';
import FileUpload from '../components/icons/FileUpload';
import Progress from '../components/icons/Progress';

const Takeout = () => {
    const { t } = useTranslation();
        
    return (
        <div id="verification">
            <section style={{textAlign:"center"}}>
                <h1 style={{marginBottom:"27px"}}>Data Takeout Request</h1>
                <h3>{t('takeout.request1')}<br/>{t('takeout.request2')}</h3>
            </section>
            <section style={{marginTop:"78px",marginLeft:"15%"}} className="card-wrap small-cards">
                <Card>
                    <IdProofIcon/>
                    <span>{t('takeout.identification1')}<br/>{t('takeout.identification2')}</span>
                </Card>
                <Card>
                    <CloudIcon2/>
                    <span>{t('takeout.cloud1')}<br/>{t('takeout.cloud2')}</span>
                </Card> 
                <Card>
                    <FileUpload/>
                    <span>{t('takeout.upload1')}<br/>{t('takeout.upload2')}</span>
                </Card>
                <Card>
                    <Progress/>
                    <span>{t('takeout.complete1')}<br/>{t('takeout.complete2')}</span>
                </Card> 
            </section>
            <section style={{textAlign:"center", marginTop:"96px"}}>
                <h3>{t('takeout.key')}</h3>
                <input className="yellow-input" placeholder={t('takeout.insertkey')} ></input>
                <button style={{marginTop:"47px",marginLeft:"45%"}}>{t('takeout.continue')}</button>
            </section>
        </div>
    )
}

export default Takeout;
