import React from 'react';
import OvalIcon from '../../images/icons/Oval.svg';
import OvalIcon2 from '../../images/icons/Oval2.svg';
import OvalIcon3 from '../../images/icons/Oval3.svg';
import OvalIcon5 from '../../images/icons/Oval5.svg';
import Truzztport from '../../images/icons/truzztport.svg';
import Truzztbox from '../../images/icons/truzztbox.svg';
import Truzztbox2 from '../../images/icons/truzztbox2.svg';
import IdentoIcon from '../../images/icons/ione.svg';
import OvalIconProgress from '../../images/icons/OvalProgress.svg';
import MobilityIcon from '../../images/icons/MobilityIcon.svg';
import TruzztportLogo from '../../images/icons/TruzztportLogo.png';
import NextcloudLogo from '../../images/icons/NextcloudLogo.png';
import CloudIcon from '../../images/icons/CloudIcon.svg';
import CloudIcon2 from '../../images/icons/cloudicon2.svg';
import UserIcon from '../../images/icons/UserIcon.svg';
import CheckedIcon from '../../images/icons/CheckedIcon.svg';
import IdProofIcon from '../../images/icons/idprooficon.svg';
import FileUpload from '../../images/icons/fileupload.svg';
import Progress from '../../images/icons/progress.svg';

export default props => {
    const iconNames = {
        OvalIcon: OvalIcon,
        OvalIcon2: OvalIcon2,
        OvalIcon3: OvalIcon3,
        OvalIcon5: OvalIcon5,
        Truzztport: Truzztport,
        Truzztbox: Truzztbox,
        Truzztbox2: Truzztbox2,
        IdentoIcon: IdentoIcon,
        OvalIconProgress: OvalIconProgress,
        MobilityIcon: MobilityIcon,
        NextcloudLogo: NextcloudLogo,
        TruzztportLogo: TruzztportLogo,
        CloudIcon: CloudIcon,
        CloudIcon2: CloudIcon2,
        UserIcon: UserIcon,
        Progress: Progress,
        CheckedIcon: CheckedIcon,
        FileUpload: FileUpload,
        IdProofIcon: IdProofIcon
    }

    return (
        <img className={props.className} src={iconNames[props.iconName]} style={{height: props.size}} alt="icon"/>
    )
}
