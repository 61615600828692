import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Card } from '../components/Card';
import Breadcrumb from '../components/Breadcrumb';
import MobilityIcon from '../components/icons/MobilityIcon';
import TruzztportLogo from '../components/icons/TruzztportLogo';
import YellowIcon from '../components/icons/YellowIcon';
import TruzztboxIcon from '../components/icons/TruzztboxIcon';
import TruzztportIcon from '../components/icons/TruzztportIcon';
import IdentoIcon from '../components/icons/IdentoIcon';
import AddIcon from '../images/AddIcon';
import AddIcon2 from '../images/AddIcon2';
import UserService from '../services/UserService';

const API_URL = process.env.REACT_APP_API_URL;

const Dashboard = () => {
    const { t } = useTranslation();
    const [screenPage, setScreenPage ] = useState('Dashboard');
    const [box, setBox] = useState(null);
    const [profile, setProfile] = useState("");
    const [mobility_group, setMobilityGroup] = useState(false);
    const [verified, setVerified] = useState(false);

    function x() {
        setInterval(fetchBox, 15000);
    }

    async function fetchBox() {
        try {
            const result = await axios.get(`${API_URL}/truzztbox`, {
                headers: {
                    'Authorization': "Bearer " + UserService.getToken()
                }
            });
            setBox(result.data);
        } catch (error) {
            console.error("An error occurred while fetching data:", error);
        }
    }

    useEffect(() => {
        fetchBox();
        x();
    }, []);

    const truzztVerify = async () => {
        setVerified(true);
        await axios.post(`${API_URL}/verify/truzzt`, {}, {
                headers: {
                    'Authorization': "Bearer " + UserService.getToken()
                }
            });
    }

    const fetchProfile = async () => {
        const result = await axios.get(`${API_URL}/verify/status`, {
                headers: {
                    'Authorization': "Bearer " + UserService.getToken()
                }
            });
            setProfile(result.data);
    }
    
    useEffect(() => {
        fetchProfile();
    }, []);

    const joinMobilityDataSpace = async () => {
        try {
            const payload = {
                group_id: "11a76fa7-cba3-4db1-9a0e-be976818441e"
            }
            await axios.put(`${API_URL}/account/group-add-user`, payload, { headers: { 'Authorization': "bearer " + UserService.getToken() } });
            setMobilityGroup(true);
        } catch(e) {
            console.log(e);
        }
    }


    const RenderDashboardPage = () => {
        return (
            <div id="dashboard">
                <section className="card-wrap small-cards">
                    <Card className="idento-gradient">
                        <IdentoIcon/>
                        <span><strong>{t('dashboard.verification')}<br />{t('dashboard.verification2')}</strong></span>
                    </Card>
                    <Card onClick={() => setScreenPage('Datenräume')}>
                        <YellowIcon/>
                        <TruzztportIcon/>
                        <span>{t('dashboard.yourData1')}<br />{t('dashboard.yourData2')}</span>
                    </Card>
                    <Card onClick={() => setScreenPage('truzzt box')}>
                        <YellowIcon/>
                        <TruzztboxIcon/>
                        <span>{t('dashboard.yourTruzztBox1')}<br />{t('dashboard.yourTruzztBox2')}</span>
                    </Card>
                    <Card onClick={() => setScreenPage('Connector')}>
                        <YellowIcon/>
                        <TruzztboxIcon/>
                        <span>{t('dashboard.addConnector1')}<br />{t('dashboard.addConnector2')}</span>
                    </Card>
                    <Card>
                        <AddIcon />
                        <span>{t('dashboard.createGroup')}<br />{t('dashboard.create')}</span>
                    </Card>
                </section>
                <section className="card-wrap big-cards">
                    <Card>
                        <h2 style={{marginTop:"8px"}}>{t('dashboard.verificationcomplete')}</h2>
                            <p style={{color: "#606060"}}>
                            {t('dashboard.verificationThankyou')}
                            </p>
                        <span className="color-orange last-update-span">
                            {t('dashboard.lastUpdate')}<strong>{new Date(profile.created).toLocaleDateString()}</strong>
                        </span>
                    </Card>
                </section>
            </div>
        )
    }

    const RenderDataroomPage = () => {
        return (
            <div id="dashboard">
                <section className="card-wrap small-cards">
                    <Card onClick={() => setScreenPage('Dashboard')}>
                        <YellowIcon/>
                        <IdentoIcon/> 
                        <span>{t('dashboard.verification')}</span>
                    </Card>
                    <Card className="idento-gradient">
                        <YellowIcon/>
                        <TruzztportIcon/>
                        <span><strong>{t('dashboard.yourData1')}<br />{t('dashboard.yourData2')}</strong></span>
                    </Card>
                    <Card onClick={() => setScreenPage('truzzt box')}>
                        <YellowIcon/>
                        <TruzztboxIcon/>
                        <span>{t('dashboard.yourTruzztBox1')}<br />{t('dashboard.yourTruzztBox2')}</span>
                    </Card>
                    <Card onClick={() => setScreenPage('Connector')}>
                        <YellowIcon />
                        <TruzztboxIcon />
                        <span>{t('dashboard.addConnector1')}<br />{t('dashboard.addConnector2')}</span>
                    </Card>
                    <Card>
                        <AddIcon />
                        <span>{t('dashboard.createGroup')}<br />{t('dashboard.create')}</span>
                    </Card>
                </section>
                <section className="card-wrap big-cards">
                    <Card to="/connector">
                        <TruzztportLogo/>
                        <button style={{marginTop: "35%"}}>
                             {t('dashboard.tojoin')}
                        </button>
                    </Card>
                    <Card>
                        <MobilityIcon/>
                        {mobility_group === false ?
                            <button onClick={() => joinMobilityDataSpace()} style={{marginTop: "25%"}}>
                                {t('dashboard.tojoin')}
                            </button> :
                            <button onClick={()=> window.location.href="/connector"} style={{marginTop: "25%"}}>
                                Datenraum anzeigen
                            </button>
                        }
                    </Card>
                    <Card>
                        <AddIcon2/>
                        <h2 style={{marginTop:"32px", fontSize:"20px"}}>{t('dashboard.Joindataroom')}</h2>
                    </Card>
                </section>
            </div>
            
        )
    }

    const RenderTruzztboxPage = () => {
        return (
            <div id="dashboard">
                <section className="card-wrap small-cards">
                    <Card onClick={() => setScreenPage('Dashboard')}>
                        <YellowIcon/>
                        <IdentoIcon/>
                        <span>{t('dashboard.verification')}</span>
                    </Card>
                    <Card onClick={() => setScreenPage('Datenräume')}>
                        <YellowIcon/>
                        <TruzztportIcon/>
                        <span>{t('dashboard.yourData1')}<br />{t('dashboard.yourData2')}</span>
                    </Card>
                    <Card className="idento-gradient">
                        <YellowIcon/>
                        <TruzztboxIcon/>
                        <span><strong>{t('dashboard.yourTruzztBox1')}<br />{t('dashboard.yourTruzztBox2')}</strong></span>
                    </Card>
                    <Card onClick={() => setScreenPage('Connector')}>
                        <YellowIcon />
                        <TruzztboxIcon/>
                        <span>{t('dashboard.addConnector1')}<br />{t('dashboard.addConnector2')}</span>
                    </Card>
                    <Card>
                        <AddIcon/>
                        <span>{t('dashboard.createGroup')}<br />{t('dashboard.create')}</span>
                    </Card>
                </section>
            </div>
        )
    }

    const RenderConnectorPage = () => {
        return (
            <div id="dashboard">
                <section className="card-wrap small-cards">
                    <Card onClick={() => setScreenPage('Dashboard')}>
                        <YellowIcon/>
                        <IdentoIcon/> 
                        <span>{t('dashboard.verification')}</span>
                    </Card>
                    <Card onClick={() => setScreenPage('Datenräume')}>
                        <YellowIcon/>
                        <TruzztportIcon/>
                        <span>{t('dashboard.yourData1')}<br />{t('dashboard.yourData2')}</span>
                    </Card>
                    <Card onClick={() => setScreenPage('truzzt box')}>
                        <YellowIcon/>
                        <TruzztboxIcon/>
                        <span>{t('dashboard.yourTruzztBox1')}<br />{t('dashboard.yourTruzztBox2')}</span>
                    </Card>
                    <Card  className="idento-gradient">
                        <YellowIcon />
                        <TruzztboxIcon />
                        <span><strong>{t('dashboard.addConnector1')}<br />{t('dashboard.addConnector2')}</strong></span>
                    </Card>
                    <Card>
                        <AddIcon />
                        <span>{t('dashboard.createGroup')}<br />{t('dashboard.create')}</span>
                    </Card>
                </section>
                <section className="card-wrap big-cards">
                    <Card>
                        <h2>{t('dashboard.IDSA')}</h2>
                            <p style={{color: "#606060"}}>
                             {t('dashboard.IDSA')}
                            </p>
                            <button style={{marginTop: "47%"}}>
                              {t('dashboard.toConnector')}

                            </button>
                    </Card>
                    <Card>
                        <h2>{t('dashboard.yourConnector')}</h2>
                        <ul>
                            <p style={{color: "#606060"}}>
                            {t('dashboard.Enterdata')}
                            </p>
                            <p style={{color: "#606060"}}> 
                            {t('dashboard.Runthroughthetestbed')}
                            </p>
                            <p style={{color: "#606060"}}>
                            {t('dashboard.UsetruzztPort')}
                            </p>
                        </ul>
                        <button style={{marginTop: "34%"}}>
                            {t('dashboard.startnow')}
                        </button>
                    </Card>
                </section>
            </div>
        )
    }
    
    function renderBreadcrumb() {
        return(            
            <section id="head-row">
                <Breadcrumb text={t('dashboard.youarehere')} text2={screenPage}/>
            </section>
        )
    }

    function RenderTruzztboxTiles() {
        if(!box.truzztbox) { 
            return <section className="card-wrap big-cards">
            <Card>
                <h2>{t('dashboard.yourTruzzzboxes1')}<br />truzztbox</h2>
                <ul>
                    <p style={{color: "#606060"}}>
                        {box.truzztbox ? `Status: Active` : "keine truzztbox vorhanden"}
                    </p>
                </ul>
            </Card>
            <Card to="/konfigurator">
                <h2>
                    {t('dashboard.yourTruzzzboxconfig1')}
                </h2>
                <p style={{color: "#606060"}}>
                    {t('dashboard.allinformationaboutyourconfiguredtruzzboxgroups')}
                </p>
            </Card>
        </section>
        } return <section className="card-wrap big-cards">
                    <Card to={box.truzztbox ? "/truzztbox" : ""}>
                        <h2>{t('dashboard.yourTruzzzboxes1')}<br />truzztbox</h2>
                        <ul>
                        <p style={{color: "#606060"}}>
                            {box.truzztbox ? `Status: Active` : "keine truzztbox vorhanden"}
                            </p>
                        </ul>
                    </Card>
                    <Card Card to="/konfigurator">
                        <h2>{t('dashboard.edittruzztbox')}</h2>
                        <p style={{color: "#606060"}}>
                        {t('dashboard.allinformationaboutyourconfiguredtrutzbox')}
                        </p>
                        <span className="color-orange last-update-span">{t('dashboard.lastUpdate')}<strong>29.10.2020</strong></span>
                    </Card>
                 </section>
    }

    const renderMain = () => {
        switch (screenPage) {
            case 'Dashboard':
                return (
                    <>
                        {RenderDashboardPage()}
                    </>
                )
            case 'Datenräume':
                return (
                    <>
                        {RenderDataroomPage()}
                    </>
                )
            case 'truzzt box': 
                return (
                    <>
                        {RenderTruzztboxPage()}
                        {RenderTruzztboxTiles()}
                    </>
                )
            case 'Connector': 
                return (
                    <>
                        {RenderConnectorPage()}
                    </>
                )
            default:
                return (
                    <>
                        {RenderDashboardPage()}
                    </>
                )
        }
    }
    
    const renderVerificationFirst = () => {
        return (
            <div id="dashboard">
                <section className="card-wrap small-cards">
                    <Card className="idento-gradient">
                        <IdentoIcon/>
                        <span><strong>{t('dashboard.verification')}<br />{t('dashboard.verification2')}</strong></span>
                    </Card>
                </section>
                <section className="card-wrap big-cards profile">
                    <Card  to="/verification">
                        <h2 style={{marginTop:"8px"}}>{t('dashboard.verificationchoose1')}</h2>
                        <span className="color-key"><strong>{t('dashboard.Verifeye')}</strong></span>
                            <p style={{color: "#606060"}}>
                            {t('dashboard.Verifyyourselfheretogetaccesstoalltools')}
                            </p>
                    </Card>
                    <a onClick={() => truzztVerify()}>
                        <Card>
                            <h2 style={{marginTop:"8px"}}>{t('dashboard.verificationchoose2')}</h2>
                            <span className="color-key"><strong>{t('dashboard.Truzzt')}</strong></span>
                                <p style={{color: "#606060"}}>
                                {t('dashboard.Verifyyourselfheretogetaccesstoalltools')}
                                </p>
                        </Card>
                    </a>
                </section>
            </div>
        )
    }

    if (!profile.verifyStatus && verified === false ) {
        return (
            <main>
                <>
                    {renderBreadcrumb()}
                    {renderVerificationFirst()}
                </>
            </main>
        ) 
    } else {
        return (
            <main>
                <>
                    {renderBreadcrumb()}
                    {renderMain()}
                </>
            </main>
        )
    }
}
    
export default Dashboard;
