import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import UserService from '../services/UserService';
import axios from 'axios';
import { Card } from '../components/Card';
import IdentityIcon from '../images/IdentityIcon';


const API_URL = process.env.REACT_APP_API_URL;


const Verification = () => {
    const { t } = useTranslation();
    const [verifeyeLink, setVerifeyeLink] = useState("");


    const fetchLink = async () => {
        const result = await axios.get(`${API_URL}/verify/verifeye/link`, {
                headers: {
                    'Authorization': "Bearer " + UserService.getToken()
                }
            });
            setVerifeyeLink(result.data);
    }

    useEffect(() => {
        fetchLink();
    }, []);

    return (
        <div id="verification">
            <section id="head-row">
                <div id="title-column">
                    <p id="breadcrumb">{t('dashboard.youAreHere')} &gt; <strong>{t('nav.verification')}</strong></p>
                    <h1>{t('helmet.verification')}</h1>
                </div>
            </section>
            <section className="card-wrap small-cards">
                <Card onClick={()=> window.location.href=verifeyeLink.link}>
                    <IdentityIcon />
                    <span>{t('verification.verifeye')}</span>
                </Card> 
            </section>
        </div>
    )
}

export default Verification;
