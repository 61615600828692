import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import translationDE from './translations/de.json';
import translationEN from './translations/en.json';

const resources = {
    de: {
        translation: translationDE
    },
    en: {
        translation: translationEN
    }
}

i18n.use(initReactI18next).init({
        resources,
        lng: "de",
        //keySeparator: false, // we do not use keys in form messages.welcome
        react: {
            useSuspense: false
        },
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    })

export default i18n;
