import React from 'react';

function Breadcrumb(props) {
  let after = window.location.pathname;
  let str = after.substring(1);
  URL = str.replace(/^\w/, c => c.toUpperCase());
  
  return (
    <div id="breadcrumb">
      <p>{props.text} &gt; <strong>{URL}</strong></p>
      <br/>
      <h1>{props.text2}</h1>
    </div>
  )
}

export default Breadcrumb;
