import {useTranslation} from 'react-i18next';
import CloseIcon from "../images/CloseIcon";
import {Link} from 'react-router-dom';

const MobileNav = ({setVerificationModal, open}) => {
    const {t} = useTranslation()

    return (
        <nav>
            <CloseIcon id="close-icon" onClick={() => open(false)}/>
            <ul>
                <li><Link to="/dashboard">{t('nav.dashboard')}</Link></li>
                <li><Link to="/settings">{t('nav.settings')}</Link></li>
                <hr/>
                <li>{t('nav.logout')}</li>
            </ul>
        </nav>
    )
}

export default MobileNav;
