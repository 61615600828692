import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter, Link } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import NavColumn from './components/NavColumn';
import MobileNav from "./components/MobileNav";
import TruzztLogo from "./images/icons/truzzt.png";
import UserService from './services/UserService';
import i18n from './i18n';
import Router from './routes/index';

function App() {
    const [mobileLayout, setMobileLayout] = useState(false)
    const [mobileNav, setMobileNav] = useState(false)
    const [currentLanguage, setCurrentLanguage] = useState('')
    
    const changeLanguage = (currentLanguage) => {
        i18n.changeLanguage(currentLanguage);
      }

    const [globalStyle, setGlobalStyle] = useState({
        "keyColor": "#f3af0e",
        "secondColor": "#f3af0e",
        "thirdColor": "#E73458",
        "fourthColor": "#f3af0e",
        "BackgroundGradientColor1": "#101010",
        "BackgroundGradientColor2": "#242526"
      })

    useEffect(() => {
        if (mobileNav) {
            document.body.classList.add('no-scrolling')
        } else {
            document.body.classList.remove('no-scrolling')
        }
    }, [mobileNav])

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth <= 768) {
                setMobileLayout(true)
            } else {
                setMobileLayout(false)
                setMobileNav(false)
            }
        }

        window.addEventListener('resize', handleResize)
        window.addEventListener('load', handleResize)
    })

    const GlobalStyle = createGlobalStyle`
    :root {
        --key-color: ${globalStyle.keyColor};
        --second-color:${globalStyle.secondColor};
        --third-color: ${globalStyle.thirdColor};
        --fourth-color: ${globalStyle.fourthColor};
        --background-gradient-color-1: ${globalStyle.BackgroundGradientColor1 ? globalStyle.BackgroundGradientColor1 : "#0b0b0b"};
        --background-gradient-color-2: ${globalStyle.BackgroundGradientColor2 ? globalStyle.BackgroundGradientColor2 : "#555658"};
    }
    
    body .idento-card.idento-gradient span {
        color: ${globalStyle.keyColor, '#ffffff', '#222222'};
    }
    
    body .idento-card {
        position: relative;
        overflow: hidden;
        border-radius: 11px;
        padding: 2.5rem;
        background: ${props => props.theme.cardcolor};
        box-shadow: ${props => props.theme.boxshadow};
    }
    
    body .idento-card.idento-gradient {
        background: ${props => props.theme.gradientcard};
        position: relative;
        overflow: hidden;
    }

    body .idento-card.idento-gradient span {
        color: ${props => props.theme.gradientcardfont};
    }

    body .idento-card span {
        color: ${props => props.theme.cardfont};
    }

    #root main .card-wrap .idento-card svg {
        fill: ${props => props.theme.svgcolor};
    }

    body {
        background: ${props => props.theme.bg};
        background-size: cover;
        background-repeat: no-repeat;
        background-attachment: fixed;
        font-family: "Avenir W01", sans-serif;
        font-size: 1.4rem;
        padding: 30px 50px;
        min-height: 100vh;
    }
    
    body h1, body h2, body h3, body h4, body h5, body h6 {
        color: ${props => props.theme.color};
        line-height: 1.4;
        margin: 0;
        font-weight: 300;
    }
    
    body p, body a, body span, body li, body button, body input, body select, body textarea, body label, body option {
        font-size: 1.4rem;
        font-weight: 300;
        color: ${props => props.theme.color2};
        line-height: 1.4;
        margin: 0;
    }
    
    #root nav ul li.new-badge:after {
        position: absolute;
        content: "Neu";
        font-size: .8em;
        font-weight: 700;
        color: ${props => props.theme.color2};
        -webkit-transform: translateX(20px);
        transform: translateX(20px);
    }

    #root main #head-row #breadcrumb {
        margin-bottom: 1em;
        font-size: 1.6rem;
        color: ${props => props.theme.color};
    }

    #root main #head-row #breadcrumb strong {
        color: ${props => props.theme.color};
    }`;

    // Truzzt default theme
    GlobalStyle.defaultProps = {
        theme: {
        bg: "linear-gradient(215.01deg, #101010 1.34%, #242526 104.24%)",
        color: "white",
        color2: "white",
        cardcolor: "linear-gradient(16.25deg, #1D1D1D 0.79%, #242424 103.27%);",
        gradientcard: "linear-gradient(40.36deg, #F7B500 2.23%, #FCDD5E 120.74%);",
        gradientcardfont: "color: #222222;",
        svgcolor: "#f3af0e",
        boxshadow: "0 25px 25px -20px rgb(0 0 0 / 10%), 0 40px 40px 0 rgb(0 0 0 / 10%);"
        }
    }
  
    // Test mode theme
    let testTheme = {
        bg: "#FFFFFF;",
        color: "black",
        color2: "#A1A1A1",
        cardcolor: "white",
        gradientcard: "linear-gradient(16.25deg, #1D1D1D 0.79%, #242424 103.27%);",
        gradientcardfont: "white",
        cardfont: "black",
        svgcolor: "#A1A1A1",
        boxshadow: "-6px 11px 30px rgba(0, 0, 0, 0.188697);",
    };

    const NormalMode = () => {
        return (
            <div>
                <GlobalStyle/>
            </div>
        )
    }

    const TestMode = () => {
        return (
            <div>
                <GlobalStyle theme={testTheme}/>
            </div>
        )
    }

    const SwitchButton = () => {
        const switched = true;
        if (switched) {
          return <NormalMode />;
        }
        return <TestMode />;
    }

    return (
        <BrowserRouter>
            <SwitchButton style={{color:'black'}} />
            <header>{UserService.isLoggedIn() ? mobileLayout ? mobileNav &&
                <MobileNav open={setMobileNav} /> 
                :
                <Link to="/dashboard">  
                <img src={TruzztLogo} alt="Logo" href="/dashboard" style={{width: "102px", marginBottom: "70px", cursor: "pointer"}}/>
                </Link> : ""}
                {UserService.isLoggedIn() && !mobileLayout ?
                    <select 
                        onChange={(event) => {changeLanguage(event.target.value);setCurrentLanguage(event.target.value)}}
                        value={currentLanguage} style={{color:"black"}}
                    >
                        <option style={{color:'black'}} value="de">de</option>
                        <option style={{color:'black'}} value="en">en</option>
                    </select>
                :
                ""}
            </header>
            {1 === 1 ? mobileLayout ? mobileNav &&  
                <MobileNav open={setMobileNav} />
                :
                <NavColumn /> : ""}
            <main>
                <Router></Router>
                <br/>
            </main>
        </BrowserRouter>
    );
}

export default App;
