import React from 'react'
import plusIcon from '../images/icons/plus-icon.svg'
import minusIcon from '../images/icons/minus-icon.svg'
import Slider from 'react-input-slider'

const SliderComponent = ({stepSize, minValue, maxValue, value, sliderType, handleChange})  => {

    const handlePlus = () => {
        handleChange(sliderType, value + stepSize)
    }

    const handleMinus = () => {
        handleChange(sliderType, value-stepSize)
    }

    const renderMinusSign = () => {
        if (value > minValue) {
            return  <img src={minusIcon} className="minus-button" alt="minus-button" onClick={() => handleMinus()} />
        }
        return null;
    }

    const renderPlusSign = () => {

        if (value < maxValue) {
            return <img src={plusIcon} className="plus-button" alt="plus-button" onClick={ () => handlePlus()} />
        }
        return null
    }

    return (
        <>
            <span className="sliderValue">{value} {sliderType !== 'cpu' && sliderType !== 'count' ? 'GB' : ''}</span>
            {renderMinusSign()}
            <Slider
                xstep={stepSize}
                xmin={minValue}
                xmax={maxValue}
                x={value}
                onChange={({ x }) => handleChange(sliderType, x )}
                styles={{
                    track: {
                        backgroundColor: '#808080',
                        overflow: 'hidden',
                        height: 30,
                        borderRadius: 15,
                        width: '100%',
                    },
                    active: {
                        background: 'linear-gradient(90deg, rgba(248,200,0,1) 0%, rgba(247,181,0,1) 100%)',
                        borderRadius: 0
                    },
                    thumb: {
                        display: 'none'
                    }
                }}
            />
            {renderPlusSign()}
            <input type="text" name={sliderType} value={value} readOnly/>
        </>
    );
}

export default SliderComponent;
