import React from 'react'
import Icon from './Icon'

export default () => {
    return (
        <div className="cls-4">
            <a target="_blank"><Icon width="18px" height="18px" iconName="IdentoIcon"/></a>
        </div>
    )
}
