import { useTranslation } from 'react-i18next';
import { Card } from '../components/Card';


const DataDashboard = () => {
        
    return (
        <div>
            <h1>Data Dashboard</h1>
        </div>
    )
}

export default DataDashboard;
