import Connectorservice from '../components/ConnectorService';
import { useTranslation } from 'react-i18next';

const Select = () => {
    const { t } = useTranslation();

    return (
        <div>
            <section id="head-row">
                <div id="breadcrumb">
                    <p>{t('dashboard.youarehere')} &gt; <strong>EDC Connector</strong></p>
                    <br/>
                    <h1>EDC Connector</h1>
                </div>
            </section>
            <div id="select-container2">
                <Connectorservice/>
            </div>
        </div>
    )
}

export default Select
