import { useState } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios";

const Connectorservice = ( ) => {
    const { t } = useTranslation();
    const [IDS_ID, setIDS_ID] = useState("")
    const [Connector, setConnector] = useState("")
    const [Text, setText] = useState("")
    const [Curator, setCurator] = useState("")
    const [Maintainer, setMaintainer] = useState("")
    const [BrokerUrl, setBrokerUrl] = useState("")
    const [ClearingUrl, setClearingUrl] = useState("")
    const [TokenUrl, setTokenUrl] = useState("")
    const [DapsJwksUrl, setDapsJwksUrl] = useState("")
    const [ClientCertificate, setClientCertificate] = useState()
    const [ConnectorName, setConnectorName] = useState("")
    const [CompanyName, setCompanyName] = useState("")

    const payload = {}

    const onSubmitHandler = () => {
        payload.ids_id = IDS_ID
        payload.ids_title = Connector
        payload.ids_description = Text
        payload.ids_curator = Curator
        payload.ids_maintainer = Maintainer
        payload.ds_broker_base_url = BrokerUrl
        payload.ds_clearinghouse_log_url = ClearingUrl
        payload.ds_daps_token_url = TokenUrl
        payload.ds_daps_jwks_url = DapsJwksUrl
        payload.ge_connector_name = ClientCertificate
        payload.ge_company_name = ConnectorName
        // Daps Cert als base64
        payload.ds_daps_certificate = CompanyName
        console.log(JSON.stringify(payload))
        // axios post call für die zukünftige api
        // axios.post(`${API_URL}/connector-service/add-mr`, payload, { headers: { 'Authorization': "bearer " + UserService.getToken() } });
    }

    const inputChangeHandler = (setFunction, event) => {
        setFunction(event.target.value)
    }

    const Main = () => {
        return (
            <div id="connectorcolumn">
                <form onSubmit={onSubmitHandler}>
                    <div className="column-1">
                        <h2 className="column-text">{t('connector.selfdescription')}</h2>
                        <h5 className="column-text">IDS ID</h5>
                        <input className={`new-password`} onChange={(e)=>inputChangeHandler(setIDS_ID, e)} type="text" placeholder={t('connector.idexample')}></input>
                        <h5 className="column-text">{t('connector.title')}</h5>
                        <input className={`new-password`} onChange={(e)=>inputChangeHandler(setConnector, e)} type="text" placeholder={t('connector.myexampleconnector')}></input>
                        <h5 className="column-text">IDS {t('connector.description')}</h5>
                        <input className={`new-password`} onChange={(e)=>inputChangeHandler(setText, e)} type="text" placeholder={t('connector.myexampledescription')}></input>
                        <h5 className="column-text">IDS Curator</h5>
                        <input className={`new-password`} onChange={(e)=>inputChangeHandler(setCurator, e)} type="text" placeholder={t('connector.exampleurl')}></input>
                        <h5 className="column-text">IDS Maintainer</h5>
                        <input className={`new-password`} onChange={(e)=>inputChangeHandler(setMaintainer, e)} type="text" placeholder={t('connector.exampleurl')}></input>
                    </div>
                    <div className="column-2">
                        <h2 className="column-text">{t('connector.configuration')}</h2>
                        <h5 className="column-text">{t('connector.connectorname')}</h5>
                        <input className={`new-password`} onChange={(e)=>inputChangeHandler(setConnectorName, e)} type="text" placeholder={t('connector.exampleconnector')}></input>
                        <h5 className="column-text">{t('connector.companyname')}</h5>
                        <input className={`new-password`} onChange={(e)=>inputChangeHandler(setCompanyName, e)} type="text" placeholder={t('connector.examplecompany')}></input>
                    </div>
                    <div className="column-3">
                        <h2 className="column-text">{t('connector.database')}</h2>
                        <h5 className="column-text">Broker base URL</h5>
                        <input className={`new-password`} onChange={(e)=>inputChangeHandler(setBrokerUrl, e)} type="text" placeholder="https://broker.dev.mobility-dataspace.eu"></input>
                        <h5 className="column-text">Clearing log URL</h5>
                        <input className={`new-password`} onChange={(e)=>inputChangeHandler(setClearingUrl, e)} type="text" placeholder="https://clearing.dev.mobility-dataspace.eu/message/log"></input>
                        <h5 className="column-text">Daps Token URL</h5>
                        <input className={`new-password`} onChange={(e)=>inputChangeHandler(setTokenUrl, e)} type="text" placeholder="https://daps.dev.mobility.eu/token"></input>
                        <h5 className="column-text">DAPS Jwks URL</h5>
                        <input className={`new-password`} onChange={(e)=>inputChangeHandler(setDapsJwksUrl, e)} type="text" placeholder="https://daps.dev.mobility.eu/jwks.json"></input>
                        <h5 className="column-text">DAPS Client {t('connector.cert')}</h5>
                        <input className={`new-password2`} onChange={(e)=>inputChangeHandler(setClientCertificate, e)} type="file" placeholder=""></input>
                    </div>
                    <div className="column-4">
                        <button type="submit">{t('connector.submit')}</button>
                    </div>
                </form>
            </div>
        )
    }

    return (
        <>
            {Main()}
        </>
    )

}

export default Connectorservice;
