import React from 'react';
import Icon from './Icon';

export default () => {
    return (
        <div className="cls-12">
            <a target="_blank"><Icon className="cls-19" width="8px" height="8px" iconName="NextcloudLogo"/></a>
        </div>
    )
}
