const Settings = () => {

    const AUTH_URL = `${process.env.REACT_APP_AUTH_URL}/realms/truzzt_id/account/#/`
        
    return (
        <div>
            <iframe loading='eager' referrerPolicy='no-referrer' className="iframe" src={AUTH_URL}></iframe>
        </div>
    )
}

export default Settings;
