import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Login from '../pages/Login';
import Verification from '../pages/Verification';
import Truzztbox from '../pages/Truzztbox';
import Configurator from '../pages/Configurator';
import Dashboard from '../pages/Dashboard';
import Settings from '../pages/Settings';
import Connectorservice from '../pages/ConnectorService';
import Takeout from '../pages/Takeout';
import DataDashboard from '../pages/DataDashboard';


const AppRouter = () => {
    return (
        <Switch>
            <Route exact path="/" component={Login}></Route>
            <Route exact path="/connectorservice" component={Connectorservice} />
            <Route exact path="/konfigurator" component={Configurator} />
            <Route path="/login" component={Login}></Route>
            <Route path="/verification" component={Verification}></Route>
            <Route path="/truzztbox" component={Truzztbox}></Route>
            <Route path="/dashboard" component={Dashboard}></Route>
            <Route path="/dashboard" component={Dashboard}></Route>
            <Route path="/datadashboard" component={DataDashboard}></Route>
            <Route path="/settings" component={Settings}></Route>
            <Route path="/takeout" component={Takeout}></Route>
        </Switch>
    )
}

export default AppRouter;
