import ConfigContent from "../components/ConfiguratorContent";
import { useTranslation } from 'react-i18next';

const Configurator = () => {
    const { t } = useTranslation();

    return (
        <div>
            <div id="breadcrumb">
                <p>{t('dashboard.youarehere')} &gt; <strong>{t('customisationColumn.configurator')}</strong></p>
                <br/>
                <h1>{t('customisationColumn.configurator')}</h1>
            </div>
            <br/>
            <br/>
            <ConfigContent/>
        </div>
    )
}

export default Configurator;
